<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white fixed md:static"
		>
			<span class="ml-2 font-bold">Agregando cliente</span>
			<div class="flex w-1/2 items-center justify-between">
				<div class="flex items-center justify-center">
					<button
						@click="addNewArrow"
						class="font-bold py-2 px-4 inline-flex items-center focus:outline-none"
					>
						<svg
							id="Capa_1"
							enable-background="new 0 0 551.13 551.13"
							height="512"
							viewBox="0 0 551.13 551.13"
							width="512"
							class="h-6 w-6 mr-2 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
							/>
							<path
								d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
							/>
						</svg>
						<span class="underline">Añadir cliente</span>
					</button>
				</div>
				<div>
					<button
						@click="guardarClientes"
						class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
					>
						Guardar Clientes
					</button>
				</div>
				<div class="flex items-center justify-center">
					<router-link to="/Clientes"
						><button
							@click="$emit('click')"
							class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
						>
							<svg
								version="1.1"
								id="Capa_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 368.022 368.022"
								style="enable-background:new 0 0 368.022 368.022;"
								class="h-6 w-6"
								xml:space="preserve"
							>
								<path
									style="fill:#D7443E;"
									d="M314.101,54.133c71.79,71.847,71.744,188.287-0.103,260.077s-188.287,71.744-260.077-0.103 c-71.75-71.806-71.75-188.167,0-259.974c71.381-71.847,187.49-72.224,259.337-0.843C313.54,53.57,313.821,53.851,314.101,54.133z"
								/>
								<polygon
									style="fill:#ffffff;"
									points="275.439,124.663 215.88,184.223 275.439,243.782 243.57,275.651 184.011,216.092 124.452,275.651 92.582,243.782 152.142,184.223 92.582,124.663 124.452,92.794 184.011,152.353 243.57,92.794"
								/>
							</svg>
							<span class="underline ml-2">Cancelar</span>
						</button></router-link
					>
				</div>
			</div>
		</div>

		<div v-if="showMessage" class="py-8 flex justify-center">
			<p>{{ message }}</p>
		</div>

		<div class="flex items-center justify-center ml-48 md:ml-0 mt-20 md:mt-0">
			<table class="table-auto ml-72 md:ml-0">
				<thead>
					<tr>
						<th></th>
						<th colspan="4" class="border-2 px-4 py-2 bg-gray-200">
							Agregando Cliente
						</th>
					</tr>
					<tr>
						<th></th>
						<th class="border-2 px-4 py-2">Nombre y Apellido</th>
						<th class="border-2 px-4 py-2">C.I.</th>
						<th class="border-2 px-4 py-2">Telefono</th>
						<th class="border-2 px-4 py-2">Dirección</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="k in Clientes.length" :key="k">
						<td scope="row">
							<button @click="borrarFilaIndex(Clientes.length - k)">
								<svg
									@click="deleteMessage = true"
									class="hover:opacity-100 cursor-pointer fill-current text-red-500 opacity-75"
									width="30"
									height="30"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g id="delete_sweep_24px">
										<path
											id="icon/content/delete_sweep_24px"
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M6 4H10L11 5H14V7H2V5H5L6 4ZM5 20C3.89999 20 3 19.1 3 18V8H13V18C13 19.1 12.1 20 11 20H5ZM22 8H15V10H22V8ZM19 16H15V18H19V16ZM15 12H21V14H15V12ZM5 10H11V18H5V10Z"
										/>
									</g>
								</svg>
							</button>
						</td>
						<td class="border-2 px-4 py-2">
							<input
								type="text"
								class="focus:outline-none"
								v-model="Clientes[Clientes.length - k].nombre"
							/>
						</td>
						<td class="border-2 px-4 py-2">
							<input
								type="text"
								class="focus:outline-none"
								v-model="Clientes[Clientes.length - k].ci"
							/>
						</td>
						<td class="border-2 px-4 py-2">
							<input
								type="text"
								class="focus:outline-none"
								v-model="Clientes[Clientes.length - k].telefono"
							/>
						</td>
						<td class="border-2 px-4 py-2">
							<input
								type="text"
								class="focus:outline-none"
								v-model="Clientes[Clientes.length - k].direccion"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import gql from "graphql-tag";

export default {
	name: "AgregarCliente",

	data() {
		return {
			Clientes: [
				{
					nombre: "",
					ci: "",
					telefono: "",
					direccion: ""
				}
			],
			showMessage: false,
			message: ""
		};
	},

	methods: {
		addNewArrow() {
			this.Clientes.push({
				nombre: "",
				ci: "",
				telefono: "",
				direccion: ""
			});
		},
		borrarFilaIndex(index) {
			this.Clientes.splice(index, 1);
		},
		guardarClientes: async function() {
			if (this.Clientes.length > 0) {
				this.showMessage = false;
				let nuevosClientes = [];
				for (let i = 0; i < this.Clientes.length; i++) {
					nuevosClientes.push({
						name: this.Clientes[i].nombre,
						cedula_identidad: this.Clientes[i].ci,
						numero_telefono_movil: this.Clientes[i].telefono,
						address: this.Clientes[i].direccion
					});
				}
				this.$store.state.isLoading = true;
				await this.$apollo
					.mutate({
						mutation: gql`
							mutation($data: [UsersUpdate!]) {
								crearClienteNuevo(data: $data) {
									id
								}
							}
						`,
						variables: {
							data: nuevosClientes
						}
					})
					.then(data => {
						this.message = "Clientes Guardados Con Exito.";
						this.$store.state.isLoading = false;
						this.showMessage = true;
					})
					.catch(error => {
						this.$store.state.isLoading = false;
						this.message = error;
						this.showMessage = true;
					});
			} else {
				this.message = "Por Favor Cargar Clientes";
				this.showMessage = true;
			}
		}
	}
};
</script>
